import React, { FunctionComponent } from "react"
import { PageProps } from "../../../types/page"
import { ScriptureDetailsPage } from "../components/scripture-details-page/scripture-details-page"
import { Page } from "../../../components/page/page"
import { getScriptureMetadata } from "../mappers/scriptures-mapper"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"
import { getScriptureTranslationId } from "../scripture-utils"

export const ScriptureBookTemplate: FunctionComponent<PageProps<null>> = (
  props
) => {
  const { id } = props.pageContext
  const metadata = getScriptureMetadata(id || "", 0)

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{
        defaultSearchIndex: "scripture",
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Scriptures,
          {
            label: getScriptureTranslationId(id),
            link: `${props.uri}`
          }
        ]
      }}
    >
      <ScriptureDetailsPage
        scripture={{ book: id || "", slug: id || "", chapter: 0 }}
      />
    </Page>
  )
}

export default ScriptureBookTemplate
